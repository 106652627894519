import { render, staticRenderFns } from "./archive.html?vue&type=template&id=2c0c4dcc&scoped=true&"
import script from "./archive.ts?vue&type=script&lang=ts&"
export * from "./archive.ts?vue&type=script&lang=ts&"
import style0 from "./archive.scss?vue&type=style&index=0&id=2c0c4dcc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0c4dcc",
  null
  
)

/* custom blocks */
import block0 from "./archive.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Farchive%2Farchive.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
