import { Component, Vue } from 'vue-property-decorator';
import ExportArchive from '@/views/archive/export-archive/export-archive.vue'; // @ is an alias to /src
import ExportAutomatic from '@/views/archive/export-automatic/export-automatic.vue'; // @ is an alias to /src

@Component({
  components: {
    ExportArchive,
    ExportAutomatic,
  },
})
export default class Archive extends Vue {
  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }
}
