import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import searchData from '@/shared/model/smallPayloadModels/searchData';
import { Selection } from '@/shared/model/selection';
import expArc, { ExportArchive } from '@/shared/model/exportArchive';
import { ROUTES } from '@/router/routesEnum';
import expAuto, { ExportAutomatic } from '@/shared/model/exportAutomatic';
import CustomExportDialog from './custom-export-dialog/custom-export-dialog.vue';


const logger = new Logger('refinament-form');
const articleModule = namespace('article');
const exportArchiveModule = namespace('exportArchive');
const exportAutomaticModule = namespace('exportAutomatic');
const authModule = namespace('auth');

@Component({
  components: {CustomExportDialog},
})
export default class RefinamentForm extends Vue {
  @exportArchiveModule.Action('updateExportArchive')
  private actionUpdateExportArchive!: any;
  @exportAutomaticModule.Action('updateExportAutomatic')
  private actionUpdateExportAutomatic!: any;

  @authModule.Getter('azureUser')
  private azureUser!: any;

  @Prop()
  private item!: Selection;

  private exportArchive: ExportArchive = expArc.parse({});

  get noExportRecordSelected() {
    return !this.item?.id;
  }
  get isDias() {
    return this.exportType?.toLowerCase() == 'dias';
  }

  exportType = '';

  get exportTypeCondition() {
    return (this.exportType && this.exportType != 'custom') || (this.exportType == 'custom' && this.selectedTemplate.name)
  }

  exportTime = '';
  exportPlace = '';
  exportFileFormat = '';
  exportAmount = '';

  isExporting = false;
  exportData() {
    if (this.exportTime == 'once') {
      this.createExportArchive();
    } else {
      this.createExportAutomatic();
    }
  }

  //#region `Create export automatic` (daily, weekly or monthly)
  createExportAutomatic() {
    let payload: ExportAutomatic = this.mapSelectionAndExportAuto();

    logger.info('exportDataAutomatic');
    if (this.selectedTemplate?.fields) {
      payload.activeExportTemplateId = this.selectedTemplate.id;
    }

    this.isExporting = true;
    this.actionUpdateExportAutomatic(payload)
      .then((result: any) => {
        this.$router.push({ name: ROUTES.archive });
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.isExporting = false;
      });
  }

  mapSelectionAndExportAuto(): ExportAutomatic {
    let result: ExportAutomatic = expAuto.parse({});
    result.name = this.item.name;
    result.exportType = this.exportTypes[this.exportType];
    result.exportTime = this.exportTime;
    result.exportPlace = this.exportPlace;
    result.exportFileFormat = this.exportFileFormat;
    result.exportAmount = this.exportAmount;

    result.selectionId = this.item.id;
    result.selection = this.item;

    if (this.selectedTemplate.name) {
      result.customTemplateName = this.selectedTemplate.name;
    }

    return result;
  }
  //#endregion

  changeExportType(value: any) {
    console.log('changeExportType value :>> ', value);
    if (value=='custom') {
      this.showExportDialog();
    } else {
      this.selectedTemplate = '';
    }
    // (PX-150) discussed with Chaslau to reset checkbox data if change exportType
    this.resetExportCheckboxesData();
  }

  //#region createExportArchive (once)
  private createExportArchive() {
    let mappingResult: ExportArchive = this.mapSelectionAndExportArchive();

    if (this.selectedTemplate?.fields) {
      mappingResult.customFields = this.selectedTemplate.fields;
    }
    logger.info('exportDataOnce');
    this.isExporting = true;
    // for now only `All` (Gesamt) export supported with `Once` time // TODO: extend later
    this.actionUpdateExportArchive(mappingResult)
      .then((result: any) => {
        this.$router.push({ name: ROUTES.archive });
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.isExporting = false;
      });
  }

  private mapSelectionAndExportArchive() {
    // used `ignore some fields` approach from here https://dev.to/darksmile92/js-use-spread-to-exclude-properties-1km9
    let mappingResult = { ...this.exportArchive, ...this.item }; // copy filter data from `Selection` object to `ExportArchive`
    mappingResult.id = '';
    mappingResult.createdAt = '';

    mappingResult.exportType = this.exportTypes[this.exportType];
    mappingResult.exportFileFormat = this.exportFileFormat;
    mappingResult.selectionId = this.item.id;

    if (this.exportPlace == 'email') {
      mappingResult.isSentByEmail = true;
      mappingResult.userEmail = this.azureUser.username;
      mappingResult.userName = this.azureUser.name;
    }
    if (this.selectedTemplate.name) {
      mappingResult.customTemplateName = this.selectedTemplate.name;
    }

    return mappingResult;
  }
  //#endregion

  private exportTypes: any = {
    all: 'Gesamt',
    supplier: 'Lieferanten',
    material: 'Warengruppen',
    model: 'Modelle',
    MHS: 'MHS',
    class: 'Clasen',
    ametra: 'Ametras',
    dias: 'DIAS',
    shd1: 'SHD 1',
    shd2: 'SHD 2',
    custom: 'Custom',
  };

  private getCurrentTimestamp() {
    return Math.floor(new Date().getTime() / 1000);
  }


    //#region Export-dialog (Zum Refinament )
    dialogExport = {
      show: false,
      model: {},
      OnClose: () => {
        this.dialogExport.show = false;
      },
    };

    showExportDialog() {
      logger.log('show-export-dialog');
      this.dialogExport.model = this.selectedTemplate;
      this.dialogExport.show = true;
    }
    //#endregion


    get headers() {
      let headers: {
        text: string | any;
        value: string;
        sortable?: boolean;
        width?: string;
      }[] = [
        { text: this.$t('article_name'), value: 'name' },
        { text: this.$t('van'), value: 'verbandsArtikelNummer' },
        { text: this.$t('ean'), value: 'ean' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '1%',
        },
      ];
      return headers;
    }

    private async selectionUpdateOptions(newVal: any, oldVal: any) {
      // this.articlesSearchParams.dataOption = newVal;
      // this.articlesSearchParams.filter = newVal.filter;
      // if (this.isMounted && !this.articlesIsLoading) this.getArticlesWithParams();
    }
    private async getArticlesWithParams() {
      // await this.actionGetArticles({ searchParams: this.articlesSearchParams, searchData: this.searchPayload });
    }

    get isHasSearchData() {
      return false; // this.getArticles.total === 0 || this.articlesIsLoading || this.isSearchDataHasNoValues();
    }

    selectedTemplate: any='';
    setSelectedTemplate(value: any){
      console.log('value setSelectedTemplate :>> ', value);
      this.selectedTemplate = value;
      if (!value) {
        this.resetExportCheckboxesData();
      }
    }

    resetExportCheckboxesData(){
      this.exportTime = '';
      this.exportPlace = '';
      this.exportFileFormat = '';
      this.exportAmount = '';
    }

}
