import { render, staticRenderFns } from "./export-automatic.html?vue&type=template&id=53913463&scoped=true&"
import script from "./export-automatic.ts?vue&type=script&lang=ts&"
export * from "./export-automatic.ts?vue&type=script&lang=ts&"
import style0 from "./export-automatic.scss?vue&type=style&index=0&id=53913463&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53913463",
  null
  
)

/* custom blocks */
import block0 from "./export-automatic.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Farchive%2Fexport-automatic%2Fexport-automatic.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText,VCardTitle})
