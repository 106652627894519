var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":900,"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"observer-custom-export-dialog-form",scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('v-form',{staticClass:"pa-8",on:{"submit":function($event){$event.preventDefault();return _vm.clickSave.apply(null, arguments)}}},[_c('v-toolbar',{staticClass:"Anthrazit--text text-uppercase elevation-0"},[_c('v-toolbar-title',{staticClass:"dialog-title__text"},[_vm._v(_vm._s(_vm.$t('dialog_title')))]),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-icon',{staticClass:"close-dialog__icon",attrs:{"color":"Anthrazit"},on:{"click":_vm.clickClose}},[_vm._v("mdi-window-close")])],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-row',{staticClass:"flex-xs-wrap"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-left text-subtitle-1"},[_vm._v(_vm._s(_vm.$t('text')))]),_c('d4y-table',{attrs:{"isNameAsRef":true,"allowAdd":true,"allowEdit":true,"allowDelete":true,"haveMoreActions":true,"searchLabel":'Suchen',"selection":_vm.selectionObj,"selectedData":_vm.selectedMapped,"tableHeaderText":_vm.tableText,"footerProps":{itemsPerPageOptions: [10, 25, 50, 100,-1]},"isFooterBtnDisabled":_vm.isHasSearchData,"loading":_vm.articlesIsLoading,"serverItemsLength":_vm.exportTemplates.total,"items":_vm.exportTemplates.items,"headers":_vm.headers,"options":_vm.articlesSearchParams.dataOption},on:{"click:delete":_vm.deleteExportTemplate,"action:add":_vm.addExportTemplate,"click:avatar":_vm.editExportTemplate,"click:edit":_vm.editExportTemplate,"click:row":_vm.editExportTemplate,"update:selected":_vm.selectedItem,"update:options":_vm.selectionUpdateOptions},scopedSlots:_vm._u([{key:"fields",fn:function(ref){
var item = ref.item;
return [(item.fields.length > 100)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-block",staticStyle:{"max-width":"300px"}},'span',attrs,false),on),[_vm._v(_vm._s(item.fields.length > 100 ? ((item.fields.replaceAll(',', ', ').substring(0,100)) + "..."): item.fields.replaceAll(',', ', ')))])]}}],null,true)},[_c('span',{staticClass:"d-block",staticStyle:{"max-width":"400px"}},[_vm._v(_vm._s(item.fields.replaceAll(',', ', ')))])]):_c('span',{staticClass:"d-block",staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(item.fields.replaceAll(',', ', ')))])]}},{key:"createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.preisbindungBis,_vm.$i18n.locale))+" ")]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"dialog__buttons"},[_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('d4y-btn',{staticClass:"save-btn white--text text-capitalize px-8",attrs:{"disabled":_vm.isSaving,"loading":_vm.isSaving,"color":"orange-btn"},on:{"click":_vm.clickSave}},[_vm._v(_vm._s(_vm.$t('save'))+" ")]),_c('d4y-btn',{staticClass:"close-btn Anthrazit--text text-capitalize px-7",attrs:{"depressed":"","color":"transparent"},on:{"click":_vm.clickClose}},[_vm._v(_vm._s(_vm.$t('close')))])],1)],1)]}}])})],1),(_vm.dialogFieldSelection.show)?_c('article-fields-dialog',{attrs:{"dialog":_vm.dialogFieldSelection.show,"list-model":_vm.dialogFieldSelection.model},on:{"click:close":_vm.dialogFieldSelection.OnClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }