export interface ReportFilterData {
  warengruppen: [];
  lieferantenNummern?: [];
  articlesMarkerNames: string[];
  modelMarkerNames: string[];
  lieferantenNummer: number;
  responsibleNames: string[];

  name: string;
  title: string;
  isMaintained: boolean | null;
  reportType: string;

  IsOneFileForSelectedSuppliers: boolean;
  isPdf: boolean;
}

function defaultData(): ReportFilterData {
  return {
    warengruppen: [],
    lieferantenNummern: [],
    articlesMarkerNames: [],
    modelMarkerNames: [],
    lieferantenNummer: 0,
    responsibleNames: [],

    name: '',
    title: '',
    isMaintained: null,
    reportType: '',

    IsOneFileForSelectedSuppliers: false,
    isPdf: true,
  };
}

export default {
  defaultData,
};
