

export function csvToArr(stringValue: string) {
  // Add logic
  const [keys, ...rest] = stringValue
    .trim()
    .split("\n")
    .map((item) => item?.replaceAll('\"','').split(','));

    // need to use `?.replaceAll('\"','')` to avoid extra quotes to put data in Autocomplete (otherwise object will be not `key:"value"` , but `"key":""value"")

    // keys.forEach((key) => key = key?.replaceAll('\"',''));

  const formedArr = rest.map((item) => {
    const object:any = {};
    keys.forEach((key, index) => (object[key] = item[index]));
    // `at` generate `Property 'at' does not exist on type 'string[]'` error
    return object;
  });

  return formedArr;
}


// copied data without changes directly from CSV (after deleting with regex NOT needed columns)
export const csvSupplierData =  `"Field","KeyI18n"
"Id", ""
"MongoId", ""
"Uuid", "ArticleId"
"Name", ""
"Status", ""
"Klassifikation", ""
"ModellStatus", ""
"ModellangebotStatus", ""
"Created", ""
"LieferantId", ""
"LieferantenNummer", ""
"LieferantName", ""
"Lieferzeit", ""
"Typname", ""
"Modellname", ""
"ProduktgruppeName", ""
"ProduktgruppeId", ""
"VerbandsArtikelNummer", ""
"EAN", ""
"HerstellerArtikelNummer", ""
"PreisbindungBis", ""
"Warengruppe", ""
"Bilder", ""
"ShopBilder", ""
"ModellKunde", ""
"ShopLaufzeitBis", ""
"HerstellerArtikelName", ""
"Breite", ""
"Hoehe", ""
"Tiefe", ""
"Laenge", ""
"Durchmesser", ""
"Masse", ""
"Gewicht", ""
"Zolltarifnummer", ""
"HerkunftProduktionsland", ""
"Shop_Headline", ""
"ShopAusfuehrung", ""
"ShopEigennameBestelltext", ""
"OnlineLieferzeit", ""
"HerstellerAuspraegungsName", ""
"AusgelaufenZum", ""
"EKFakturierpreis", ""
"EKNettoNetto", ""
"EKZuteilung", ""
"EKZuteilungNettoNetto", ""
"EKKernsortiment", ""
"EKKernsortimentNettoNetto", ""
"UVPHerst", ""
"UVPVME", ""
"Aktionspreis", ""
"Onlineshop_Artikel", ""
"DropshippingArtikel", ""
"AnzahlFuesseRollen", ""
"AnzahlLeuchten", ""
"AnzahlSetProdukte", ""
"Batterietyp", ""
"Energieeffizienzklasse", ""
"Energielabel", ""
"BefestigungsmaterialEnthalten", ""
"FarbeHerstellerbezeichnung", ""
"FarbeSuchfarbe", ""
"Fusshoehe", ""
"Fassungsvermoegen", ""
"HerstellerIcon", ""
"Kopfteilhoehe", ""
"LeuchtmittelEnthalten", ""
"LeuchtmittelTyp", ""
"LeuchtmittelTyp2", ""
"Leuchtenfunktion", ""
"Inhalt", ""
"Lieferzustand", ""
"Liegehoehe", ""
"Materialart", ""
"Materialeigenschaft", ""
"Montageart", ""
"Nassraumgeeignet", ""
"RegenAussengeeignet", ""
"VerstellbarkeitundFunktion", ""
"TragfaehigkeitBelastbarkeit", ""
"Sitzbreite", ""
"StellflaecheBreite", ""
"StellflaecheTiefeLinks", ""
"StellflaecheTiefeRechts", ""
"Shop_Langtext", ""
"VKText", ""
"SEOText", ""
"ShopSEOText", ""
"BatterieEnthalten", ""
"VPEMenge", ""
"VPEBezeichnung", ""
"Etikett3_30", ""
"Zusatzinformation", ""
"Etikett2_30", ""
"Etikett1_30", ""
"Bezeichnung", ""
"Waschangaben", ""
"Allergikergeeignet", ""
"PflegeIcon", ""
"Haertegrad", ""
"Matratzenart", ""
"Beleuchtet", ""
"Erweiterbar", ""
"Fussmaterial", ""
"NetzsteckerAnschluss", ""
"FussRollenArt", ""
"Armlehne", ""
"ArmlehnenArt", ""
"Ergonomisch", ""
"Gepolstert", ""
"MaterialGestell", ""
"inklLattenrost", ""
"inklMatratze", ""
"inklTopper", ""
"Liegeflaeche", ""
"AuspraegungsText", ""
"ShopHerstellerBestellNr", ""
"Rueckenhoehe", ""
"Sitzhaerte", ""
"Sitzhoehe", ""
"Sitztiefe", ""
"Sitzkomfort", ""
"TextilkennzeichnungInProzentTKG", ""
"BezugAbsatzmaterial", ""
"BezugAbsatzfarbeSuchfarbe", ""
"BezugAbsatzfarbeHerstellerbezeichnung", ""
"BezugKontrastnahtfarbeSuchfarbe", ""
"BezugKontrastnahtfarbeHerstellerbezeichnung", ""
"Kabellaenge", ""
"Geraeuschpegel", ""
"Pflegehinweis", ""
"GriffAusfuehrung", ""
"GriffFarbeHerstellerbezeichnung", ""
"GriffFarbeHerstellernummer", ""
"GriffFarbeSuchfarbe", ""
"Griffmaterial", ""
"KorpusAusfuehrung", ""
"KorpusfarbeHerstellerbezeichnung", ""
"KorpusfarbeSuchfarbe", ""
"KranzprofilAusfuehrung", ""
"Passepartout", ""
"Einbauart", ""
"Backofenfunktion", ""
"Kochfeldart", ""
"IPSchutzart", ""
"LebensdauerLeuchtmittel", ""
"LebensdauerLeuchtmittel2", ""
"Leistung", ""
"LeistungLeuchtmittel2", ""
"Lichttemperatur", ""
"LichttemperaturLeuchtmittel2", ""
"BetriebsspannungLeuchtmittel2", ""
"Sitzqualitaet", ""
"ImportedOn", ""
"LastUpdateOn", ""
"Preislistenmerker", ""
"Sortiment", ""
"Bildername", ""
"ArtikelId", ""
"WarengruppenName", ""
"AusfuehrungsNummerAlt", ""
"EKBrutto", ""
"KarenzTage", ""
"MindestbestellmengeText", ""
"ModellBeschreibung", ""
"ModellNummer", ""
"Modelltext", ""
"Rabatt1", ""
"Rabatt2", ""
"Rabatt3", ""
"Rabatt4", ""
"ShopMasse", ""
"Skonto1", ""
"Skonto2", ""
"SkontoTage", ""
"TeilNummerAlt", ""
"VEMenge", ""
`;


// NO VALUE
//"Blanar Nabytek,""
//"DE KUSSENFABRIEK,""
// "Eurolight BV,""
// "FIGURA VERMITTLUNGEN UND BE- RATUNGEN,""
// "PINUS GB,""
// "Schlitzer,""