import { ROUTES } from '@/router/routesEnum';
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import sel, { Selection } from '@/shared/model/selection';
import { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import ArticleFieldsDialog from '../article-fields-dialog/article-fields-dialog.vue';

const logger = new Logger('custom-export-dialog');
const selectionModule = namespace('selection');
const articleModule = namespace('article');
const exportTemplateModule = namespace('exportTemplate');

@Component({
  name: 'custom-export-dialog',
  components: {D4yTable, ArticleFieldsDialog},
})
export default class ExportDialog extends Vue {
  @Ref('observer-custom-export-dialog-form')
  private observerExportDialogForm!: any;

  @exportTemplateModule.Action('getExportTemplates')
  private actionGetExportTemplates!: any;
  @exportTemplateModule.Action('deleteExportTemplate')
  private actionDeleteExportTemplate!: any;
  @exportTemplateModule.Getter('getExportTemplates')
  private exportTemplates!: any;

  @articleModule.Getter('getSearchData')
  private getSearchData!: SearchData;

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop()
  private listModel!: any;

  selection: Selection = sel.parse({});

  created() {
    this.actionGetExportTemplates();
    if (this.listModel.name) {
      this.selectedMapped = [this.listModel];
    }
  }
  selectedMapped:any = []; // to send selected items (mapped in correct state) in `d4y-table`

  selectedTemplate = '';
  selectedItem(item: any) {
    console.log('selectedItem afd :>> ', item);
    console.log('selectedItem afd 0 num :>> ', item[0]);
    console.log('selectedItem afd 0 ster:>> ', item['0']);
    console.log('item.Fields :>> ', item[0]?.fields);
    if (item[0]?.fields) {
      this.selectedTemplate = item[0];
      // this.selected = item.map((x: any)=> x.Field);
      // this.exportTemplate.fields = this.selected.join(',');
    } else {
      this.selectedTemplate = ''; // reset value
    }
    // if (isDelete) {
    //   var index = this.selected.indexOf(item.Field);
    //   if (index !== -1) {
    //     this.selected.splice(index, 1);
    //   }
    // } else {
    //   this.selected.push(item.Field);

    // }
  }

  isSaving = false;
  async clickSave() {

    const result = await this.observerExportDialogForm.validate();

    if (result) {
      // this.isSaving = true;
      // this.actionGetExportTemplates(this.selection)
      //   .then((result: any) => {
      //     this.$router.push({ name: ROUTES.export });
      //   })
      //   .catch((err: any) => {
      //     logger.error(err);
      //   })
      //   .finally(() => {
      //     this.isSaving = false;
      //   });
      this.$emit('set:selectedTemplate', this.selectedTemplate )
      this.clickClose();
    }
  }


  clickClose() {
    this.$emit('click:close');
  }

  tableText = "";
  articlesIsLoading = false;
  total = 0;
  articlesSearchParams = {};
  items = [];

  selectionObj = {
    showSelect: true,
    singleSelect: true
  };

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      class?: string | string[],
      cellClass?: string | string[],
      width?: string;
    }[] = [
      { text: '', value: 'data-table-select', sortable: false, width: '50px' },
      { text: this.$t('template_name'), value: 'name',  cellClass: 'mw-200'},
      { text: this.$t('fields'), value: 'fields' },
      { text: this.$t('fieldsCount'), value: 'fieldsCount' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }


  private async selectionUpdateOptions(newVal: any, oldVal: any) {
    console.log('selectionUpdateOptions custom-export-dialog newVal :>> ', newVal);
    // this.articlesSearchParams.dataOption = newVal;
    // this.articlesSearchParams.filter = newVal.filter;
    // if (this.isMounted && !this.articlesIsLoading) this.getArticlesWithParams();
  }
  private async getArticlesWithParams() {
    // await this.actionGetArticles({ searchParams: this.articlesSearchParams, searchData: this.searchPayload });
  }

  get isHasSearchData() {
    return false; // this.getArticles.total === 0 || this.articlesIsLoading || this.isSearchDataHasNoValues();
  }

  deleteExportTemplate(item: any) {
    console.log('value delete:>> ', item);
    logger.debug('onDelete');
    this.$confirm
      .open(
        `${this.$t('dialog_delete.title')}`,
        `${this.$t('dialog_delete.message', {
          0: item.name,
        })}`,
        { okText: `${this.$t('dialog_delete.ok_btn')}` }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteExportTemplate(item.id);
          await this.actionGetExportTemplates();
        }
      });
  }

  addExportTemplate(value: any) {
    console.log('value add:>> ', value);
    this.dialogFieldSelection.model = {};
    this.showExportDialog();
  }
  editExportTemplate(value: any) {
    console.log('value edit:>> ', value);
    this.dialogFieldSelection.model = value;
    this.showExportDialog();
  }

      //#region Export-dialog (Zum Refinament )
      dialogFieldSelection = {
        show: false,
        model: {},
        OnClose: () => {
          this.dialogFieldSelection.show = false;
          this.actionGetExportTemplates();
        },
      };

      showExportDialog() {
        logger.log('show-export-dialog');
        this.dialogFieldSelection.show = true;
      }
      //#endregion

}
