import { ROUTES } from '@/router/routesEnum';
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import expTempl, { ExportTemplate } from '@/shared/model/exportTemplate';
import { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import {csvToArr, csvSupplierData}  from './csvSupplierData';

const logger = new Logger('article-fields-dialog');
const exportTemplateModule = namespace('exportTemplate');
const articleModule = namespace('article');

@Component({
  name: 'article-fields-dialog',
  components: {D4yTable},
})
export default class ExportDialog extends Vue {
  @Ref('observer-article-fields-dialog-form')
  private observerArticleFieldsDialogForm!: any;

  @exportTemplateModule.Action('updateExportTemplate')
  private actionUpdateExportTemplate!: any;

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop()
  private listModel!: any;

  exportTemplate: ExportTemplate = expTempl.parse({});

  // private selection?: {
  //   showSelect: boolean;
  //   singleSelect: boolean;
  //   customHeader: boolean;
  // };

  selectionObj = {
    showSelect: true,
    singleSelect: false
  };

  tableText = "";
  articlesIsLoading = false;
  total = 0;
  fieldsSearchParams = {};
  items:any = [];
  itemsAll: any = [];

  isFirstCall = true;


  created(){
        //load CSV data
        //https://hasnode.byrayray.dev/convert-a-csv-to-a-javascript-array-of-objects-the-practical-guide
        const dataCsv = csvToArr(csvSupplierData);
        console.log('data :>> ', dataCsv);
        this.items = dataCsv;
        this.itemsAll = [...dataCsv];

        // populate dialog model if Edit record
        if (this.listModel.name) {
          this.exportTemplate = expTempl.parse(this.listModel);
          // this.selected = this.listModel.fields.split(',');
          const fields = this.listModel.fields.split(',');

          this.selectedMapped = fields.map((x: any) => {
            return { Field: x, KeyI18n: ' ' };
          });
        }
  }
  selectedMapped:any = []; // to send selected items (mapped in correct state) in `d4y-table`

  isSaving = false;

  async clickSave() {
    const result = await this.observerArticleFieldsDialogForm.validate();

    if (result) {
      this.isSaving = true;
      this.actionUpdateExportTemplate(this.exportTemplate)
        .then((result: any) => {
          // this.$router.push({ name: ROUTES.export });
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.isSaving = false;
          this.clickClose();
        });
    }
  }

  clickClose() {
    this.$emit('click:close');
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: '', value: 'data-table-select', sortable: false, width: '50px' },
      { text: this.$t('fields'), value: 'Field' },
      { text: this.$t('keyI18n'), value: 'KeyI18n' },
      // {
      //   text: 'Actions',
      //   value: 'actions',
      //   sortable: false,
      //   width: '1%',
      // },
    ];
    return headers;
  }

  selected: any = [];
  selectedItem(item: any) {
    console.log('selectedItem afd :>> ', item);
    if (item?.length >= 0) {
      this.selected = item.map((x: any)=> x.Field);
      this.exportTemplate.fields = this.selected.join(',');
    }
    // if (isDelete) {
    //   var index = this.selected.indexOf(item.Field);
    //   if (index !== -1) {
    //     this.selected.splice(index, 1);
    //   }
    // } else {
    //   this.selected.push(item.Field);

    // }
  }

  realLength = 0;
  get realTotal(){
      return this.realLength;
  }

  currentParamsSettings:any = {};
  private async selectionUpdateOptions(newVal: any, oldVal: any) {
    console.log('selectionUpdateOptions newVal :>> ', newVal);
    // this.currentParamsSettings = newVal;
    if (this.isFirstCall) {
      newVal.filter = '';
      this.isFirstCall = false;
    }
    if (newVal.filter) {

      this.items = this.itemsAll.filter((item:any) => item.Field.toLowerCase().includes(newVal.filter.toLowerCase()))
      this.realLength = this.items.length;
      this.items = this.paginate(this.items, newVal.itemsPerPage, newVal.page)
    } else {
      // this.items = this.itemsAll;
      this.realLength = this.itemsAll.length;
      this.items = this.paginate([...this.itemsAll], newVal.itemsPerPage, newVal.page);
    }
    // this.articlesSearchParams.dataOption = newVal;
    // this.articlesSearchParams.filter = newVal.filter;
    // if (this.isMounted && !this.articlesIsLoading) this.getArticlesWithParams();
  }
  paginate(array:any, page_size: number, page_number: number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }


  private async getArticlesWithParams() {
    // await this.actionGetArticles({ searchParams: this.articlesSearchParams, searchData: this.searchPayload });
  }
}
