import { Component, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import saveList, { SaveList } from '@/shared/model/saveList';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { ArticleStatuses, CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';

const name = 'saveList-edit-dialog';
const logger = new Logger(name);
const saveListManagement = namespace('saveList');
const articleModule = namespace('article');
const articleMarkerModule = namespace('articleMarker');
const modelMarkerModule = namespace('modelMarker');
const responsibleModule = namespace('responsible');

@Component({
  name: name,
  components: {
    D4yEdit,
  },
})
export default class SaveListEditDialog extends Vue {
  @Ref('observer-add-edit-saveList-form')
  private addEditSaveListForm!: any;

  @saveListManagement.Action('getSaveList') private actionGetSaveList!: any;
  @saveListManagement.Action('updateSaveList')
  private actionUpdateSaveList!: any;

  @articleModule.Action('getArticlesWarengruppe')
  private actionGetArticlesWarengruppe!: any;
  @articleModule.Getter('getArticlesWarengruppe')
  private getArticlesWarengruppe!: any;

  @articleMarkerModule.Action('getArticleMarkers')
  private actionGetArticleMarkers!: any;
  @articleMarkerModule.Getter('getArticleMarkers')
  private getterArticleMarkers!: any;

  @modelMarkerModule.Action('getModelMarkers')
  private actionGetModelMarkers!: any;
  @modelMarkerModule.Getter('getModelMarkers')
  private getterModelMarkers!: any;

  @responsibleModule.Action('getResponsibles')
  private actionGetResponsibles!: any;
  @responsibleModule.Getter('getResponsibles')
  private getterResponsibles!: any;

  get saveListId() {
    return this.$route.params['saveListId'];
  }

  get isSaveListCreate() {
    return this.saveListId == CONST.emptyGuid;
  }

  async mounted() {
    if (this.isSaveListCreate) {
      this.saveList = saveList.parse({});
    } else {
      await this.getSaveList();
    }
    await this.getWarengruppe();
    await this.getArticleMarkers();
    await this.getModelMarkers();
    await this.getResponsibles();
  }

  private async getWarengruppe() {
    if (this.getArticlesWarengruppe?.length > 0) {
      this.productGroups = this.getArticlesWarengruppe;
    } else {
      await this.actionGetArticlesWarengruppe()
        .then((result: any) => {
          this.productGroups = result;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }
  private async getArticleMarkers() {
    if (this.getterArticleMarkers?.length > 0) {
      this.articleMarkers = this.getterArticleMarkers.items;
    } else {
      await this.actionGetArticleMarkers()
        .then((result: any) => {
          console.log('result actionGetArticleMarkers :>> ', result);
          this.articleMarkers = result.value;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  private async getModelMarkers() {
    if (this.getterModelMarkers?.length > 0) {
      this.modelMarkers = this.getterModelMarkers.items;
    } else {
      await this.actionGetModelMarkers()
        .then((result: any) => {
          console.log('result actionGetModelMarkers :>> ', result);
          this.modelMarkers = result.value;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  async getResponsibles() {
    if (this.getResponsibles?.length > 0) {
      this.responsible = this.getterResponsibles;
    } else {
      await this.actionGetResponsibles()
        .then((result: any) => {
          this.responsible = result.value;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  getSaveList() {
    this.actionGetSaveList(this.saveListId).then((response: SaveList) => {
      this.saveList = Object.assign({}, response);
      this.saveList.warengruppen = this.saveList.warengruppen.length > 0 ? this.saveList.warengruppen.split(',') : '';
      this.saveList.modelstatus = this.saveList.modelstatus.length > 0 ? this.saveList.modelstatus.split(',') : '';
      this.saveList.vArtikelstatus =
        this.saveList.vArtikelstatus.length > 0 ? this.saveList.vArtikelstatus.split(',') : '';
      this.saveList.vAuspraegungStatus =
        this.saveList.vAuspraegungStatus.length > 0 ? this.saveList.vAuspraegungStatus.split(',') : '';

      this.titleInfo = this.saveList.name;
    });
  }
  private saveList: SaveList = saveList.parse({});

  isLoading = false;
  titleInfo = 'empty';

  get isEmptySaveList() {
    return this.saveList.id == '';
  }

  get image() {
    return {
      src: 'img_saveList.svg',
      header: '',
      width: 230,
      height: 210,
    };
  }

  get types() {
    let items = [
      // TODO: (PFL-23) comment not implemented reports (discussed with Chaslau)
      // { id: 'ek', value: this.$t('type.ek') },
      { id: 'erst_info_list', value: this.$t('type.erst_info_list') }, // first_info
      { id: 'fach_list', value: this.$t('type.fach_list') }, // trade
      // { id: 'model_angebot', value: this.$t('type.model_angebot') },
      // { id: 'messer_price_list', value: this.$t('type.messer_price_list') }, // fair_price
      // { id: 'sortiment_list', value: this.$t('type.sortiment_list') }, // assortment list
      // { id: 'zuteilung_list', value: this.$t('type.zuteilung_list') }, // Allocation list
    ];
    return items;
  }

  get maintainStatuses() {
    let items = [
      { id: null, value: this.$t('maintain_status.all') },
      { id: true, value: this.$t('maintain_status.yes') },
      { id: false, value: this.$t('maintain_status.no') },
    ];
    return items;
  }

  get printOptions() {
    let items = [
      { id: 'general', value: this.$t('print_options.general') },
      { id: 'ek', value: this.$t('print_options.ek') },
    ];
    return items;
  }

  productGroups = ['1000-Sleep, 1100 Bedroom'];
  modelMarkers = ['E22'];
  actionRefs = ['-'];
  responsible = ['JW'];
  articleMarkers = ['MFHM22, MN21'];
  sorts = ['Name'];

  private statuses = Object.values(ArticleStatuses);
  modelStatuses = this.statuses;
  vArticleStatuses = this.statuses;
  vExpressionStatuses = this.statuses;

  async onClickedUpdate() {
    const result = await this.addEditSaveListForm.validate();
    if (result) {
      this.isLoading = true;

      this.saveList.id = this.saveListId;
      await this.actionUpdateSaveList(this.saveList)
        .then((response: SaveList) => {
          if (this.isSaveListCreate) {
            this.$router.push({
              name: ROUTES.list,
              params: { saveListId: response.id },
            });
          }
          this.getSaveList();
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  goToLists() {
    this.$router.push({
      name: ROUTES.lists,
    });
  }
}
