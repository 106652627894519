import { Component, Vue } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('export-archive');
const exportArchiveModule = namespace('exportArchive');

@Component({
  components: {
    D4yTable,
  },
})
export default class ExportArchive extends Vue {
  @exportArchiveModule.Action('getExportArchives')
  private actionGetExportArchives!: any;
  @exportArchiveModule.Getter('getExportArchives')
  private exportArchives!: any;
  @exportArchiveModule.Getter('getExportArchivesIsLoading')
  private exportArchivesIsLoading!: boolean;
  @exportArchiveModule.Getter('getExportArchivesTotal')
  private exportArchivesTotal!: number;
  @exportArchiveModule.Getter('getExportArchivesSearchParams')
  private exportArchivesSearchParams!: SearchParams;

  @exportArchiveModule.Action('deleteExportArchive')
  private actionDeleteExportArchive!: any;
  @exportArchiveModule.Action('downloadArchive')
  private actionDownloadArchive!: any;

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    this.isMounted = true;
    await this.getExportArchivesOnLoad();
  }

  get items() {
    return this.exportArchives.items;
  }

  private async getExportArchivesOnLoad() {
    await this.actionGetExportArchives()
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private isExportArchiveGetterEmpty() {
    return this.exportArchives?.items?.length === 0;
  }

  get tableText() {
    return `${this.exportArchives.total} files`;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('filters'), value: 'filters', sortable: false },
      { text: this.$t('type'), value: 'exportType', sortable: false },
      { text: this.$t('created_at'), value: 'createdAt' },
      { text: this.$t('export'), value: 'download', sortable: false },
      {
        text: this.$t('delete'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private async selectionUpdateOptions(newVal: any, oldVal: any) {
    this.exportArchivesSearchParams.dataOption = newVal;
    this.exportArchivesSearchParams.filter = newVal.filter;
    if (this.isMounted && !this.exportArchivesIsLoading) this.getExportArchives();
  }
  private async getExportArchives() {
    await this.actionGetExportArchives({ searchParams: this.exportArchivesSearchParams });
  }

  async downloadFile(item: any) {
    await this.actionDownloadArchive(item)
      .then((result: any) => {
        this.$confirm.open(
          `${this.$t('dialog_download.title')}`,
          `${this.$t('dialog_download.message', {
            0: item.name,
          })}`,
          { okText: 'OK', hasCancelBtn: false }
        );
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async deleteExportArchive(item: any) {
    logger.log('deleteExportArchive:', item);
    this.$confirm
      .open(
        `${this.$t('dialog_delete.title')}`,
        `${this.$t('dialog_delete.message', {
          0: item.name,
        })}`,
        { okText: `${this.$t('dialog_delete.ok_btn')}` }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteExportArchive(item.id);
          await this.actionGetExportArchives();
        }
      });
  }
}
