import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
// import { saveLists } from '@/shared/model/saveLists';
import { SearchParams } from '@/shared/model/searchParams';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import Refinament from '@/views/export-list/refinament-form/refinament-form.vue';
import { ROUTES } from '@/router/routesEnum';
import { CONST } from '@/shared/utils/Constants';

const selectionModule = namespace('selection');
const logger = new Logger('export-list');

@Component({
  components: { D4yTable, Refinament },
})
export default class ExportList extends Vue {
  @selectionModule.Getter('getSelections')
  private selections!: any;
  @selectionModule.Getter('getSelectionsTotal')
  private selectionsTotal!: number;
  @selectionModule.Getter('getSelectionsIsLoading')
  private selectionsIsLoading!: boolean;
  @selectionModule.Getter('getSelectionsSearchParams')
  private selectionsSearchParams!: SearchParams;

  @selectionModule.Action('getSelections')
  private actionGetSelections!: any;
  @selectionModule.Action('deleteSelection')
  private actionDeleteSelection!: any;

  private selection = {
    showSelect: true,
    singleSelect: true,
    customHeader: true,
  };

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get tableText() {
    return `${this.selectionsTotal} Sortimente`;
  }

  get hasSelectedRow(): string {
    return this.selections.items.some((x: any) => x.selected);
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('filters'), value: 'filters', sortable: false },
      { text: this.$t('created_at'), value: 'createdAt' },
      { text: '', value: 'data-table-select', sortable: false, width: '200px' },
      {
        text: this.$t('delete'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private async selectionUpdateOptions(newVal: any, oldVal: any) {
    this.selectionsSearchParams.dataOption = newVal;
    this.selectionsSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.selectionsIsLoading) this.getSaveLists();
  }
  private async getSaveLists() {
    await this.actionGetSelections({ searchParams: this.selectionsSearchParams });
  }

  checkedItem: Selection | null = null; // `null` passed when uncheck the row in table
  selectedItem(item: any) {
    this.checkedItem = item;
  }

  async created() {
    var promiseAll = [this.actionGetSelections()];
    await Promise.all(promiseAll);
  }

  private async deleteSelection(item: any) {
    logger.debug('onDelete');
    this.$confirm
      .open(
        `${this.$t('dialog_delete.title')}`,
        `${this.$t('dialog_delete.message', {
          0: item.name,
        })}`,
        { okText: `${this.$t('dialog_delete.ok_btn')}` }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteSelection(item.id);
          await this.actionGetSelections();
        }
      });
  }
}
