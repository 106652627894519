import { Component, Vue } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('export-automatic');
const exportAutomaticModule = namespace('exportAutomatic');

@Component({
  components: {
    D4yTable,
  },
})
export default class ExportAutomatic extends Vue {
  @exportAutomaticModule.Action('getExportAutomatics')
  private actionGetExportAutomatics!: any;
  @exportAutomaticModule.Getter('getExportAutomatics')
  private exportAutomatics!: any;
  @exportAutomaticModule.Getter('getExportAutomaticsIsLoading')
  private exportAutomaticsIsLoading!: boolean;
  @exportAutomaticModule.Getter('getExportAutomaticsTotal')
  private exportAutomaticsTotal!: number;
  @exportAutomaticModule.Getter('getExportAutomaticsSearchParams')
  private exportAutomaticsSearchParams!: SearchParams;

  @exportAutomaticModule.Action('deleteExportAutomatic')
  private actionDeleteExportAutomatic!: any;

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    this.isMounted = true;
    await this.getExportAutomaticsOnLoad();
  }

  get items() {
    return this.exportAutomatics.items;
  }

  private async getExportAutomaticsOnLoad() {
    await this.actionGetExportAutomatics()
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private isExportAutomaticsGetterEmpty() {
    return this.exportAutomatics?.items?.length === 0;
  }

  get tableText() {
    return `${this.exportAutomatics.total} files`;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('filters'), value: 'filters', sortable: false },
      { text: this.$t('type'), value: 'exportType', sortable: false },
      { text: this.$t('auto_type'), value: 'exportTime', sortable: false },
      { text: this.$t('next_export'), value: 'nextExportDate' },
      { text: this.$t('edit'), value: '', sortable: false },
      {
        text: this.$t('delete'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private async selectionUpdateOptions(newVal: any, oldVal: any) {
    this.exportAutomaticsSearchParams.dataOption = newVal;
    this.exportAutomaticsSearchParams.filter = newVal.filter;
    if (this.isMounted && !this.exportAutomaticsIsLoading) this.getExportAutomatics();
  }
  private async getExportAutomatics() {
    await this.actionGetExportAutomatics({ searchParams: this.exportAutomaticsSearchParams });
  }

  private async deleteExportAutomatic(item: any) {
    logger.log('deleteExportAutomatic:', item);
    this.$confirm
      .open(
        `${this.$t('dialog_delete.title')}`,
        `${this.$t('dialog_delete.message', {
          0: item.name,
        })}`,
        { okText: `${this.$t('dialog_delete.ok_btn')}` }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteExportAutomatic(item.id);
          await this.actionGetExportAutomatics();
        }
      });
  }
}
